<template>
	<div class="md:w-iphone13 mx-auto select-none font-poppins">
		<router-view />
	</div>
</template>

<script setup>
import _ from "lodash";
import { useProductStore } from "@/store/product";
import { useCategoryStore } from "@/store/category";
import { useAppStore } from "@/store/app";
import { useSwalStore } from "@/store/swal";
import { inject, onBeforeMount, onMounted } from "@vue/runtime-core";
const sProduct = useProductStore();
const sCategory = useCategoryStore();
const sApp = useAppStore();
const sSwal = useSwalStore();

onBeforeMount(() => {});
onMounted(async () => {
	sProduct.updateProducts();
	sProduct.updateAll();
	sCategory.updateCategories();
	var visited = localStorage.getItem("visited");
	sApp.updateVisited(JSON.parse(visited));
	sApp.getPaymentMethod();
	// setTimeout(() => {
	// 	if (!sApp.visited) {
	// 		localStorage.setItem("visited", true);
	// 		sApp.updateVisited(true);
	// 	}
	// }, 5000);
	// sSwal.swalNotif("test", 0, true);
});
</script>
<style>
/* Animation  */
.dropdown-enter-from {
	transform: translateY(-85px);
}
.dropdown-enter-to {
	transform: translateY(0);
}
.dropdown-enter-active {
	transition: all 0.4s ease-out;
}
.dropdown-leave-from {
	transform: translateY(0);
}
.dropdown-leave-to {
	transform: translateY(-85px);
}
.dropdown-leave-active {
	transition: all 0.3s ease-out;
}

.swipe-enter-from {
	transform: translateY(100vh);
}
.swipe-enter-to {
	transform: translateY(0);
}
.swipe-enter-active {
	transition: all 0.6s ease;
}
.swipe-leave-from {
	transform: translateY(0);
}
.swipe-leave-to {
	transform: translateY(100vh);
}
.swipe-leave-active {
	transition: all 0.8s ease;
}

.swipe-right-enter-from {
	transform: translateX(-750px);
}
.swipe-right-enter-to {
	transform: translateX(0);
}
.swipe-right-enter-active {
	transition: all 0.6s ease;
}
.swipe-right-leave-from {
	transform: translateX(0);
}
.swipe-right-leave-to {
	transform: translateX(-900px);
}
.swipe-right-leave-active {
	transition: all 0.8s ease;
}

.swipe-up-enter-from {
	transform: translateY(250px);
}
.swipe-up-enter-to {
	transform: translateY(0);
}
.swipe-up-enter-active {
	transition: all 0.6s ease;
}
.swipe-up-leave-from {
	transform: translateY(0);
}
.swipe-up-leave-to {
	transform: translateY(250px);
}
.swipe-up-leave-active {
	transition: all 0.8s ease;
}


.pop-swipe-left-leave-from {
	transform: translateX(0);
}
.pop-swipe-left-leave-to {
	transform: translateX(-900px);
}
.pop-swipe-left-leave-active {
	transition: all 0.8s ease;
}

.fade-leave-from {
	opacity: 1;
}
.fade-leave-to {
	opacity: 0;
}
.fade-leave-active {
	transition: all 0.5s ease;
}
/* End of Animation */
/* hide number input arrow  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
	height: 0px;
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a1a1a1;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
