import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Swal from "sweetalert2";

export const useSwalStore = defineStore("swal", () => {
	const icons = ["success", "error", "warning", "info", "question"];
	async function swalNotif(
		title,
		icon = 0,
		isToast = false,
		text,
		position = "center"
	) {
		await Swal.fire({
			toast: isToast,
			position: isToast ? "top" : position,
			showConfirmButton: false,
			timer: 1500,
			timerProgressBar: true,
			icon: icons[icon],
			title: title,
			text: text,
		});
	}
	async function swalConfirmation(
		title = "Are you sure?",
		icon = 2,
		text = "You won't be able to revert this!",
		confirmButtonText = "Yes, delete it!",
		cancelButtonText = "Cancel",
		confirmButtonColor = "#3085d6",
		cancelButtonColor = "#d33"
	) {
		return await Swal.fire({
			title: title,
			text: text,
			icon: icons[icon],
			showCancelButton: true,
			confirmButtonColor: confirmButtonColor,
			cancelButtonColor: cancelButtonColor,
			confirmButtonText: confirmButtonText,
			cancelButtonText: cancelButtonText,
		}).then((result) => {
			if (result.isConfirmed) {
				return true;
			}
			return false;
		});
	}

	return {
		swalNotif,
		swalConfirmation,
	};
});
