const cartRoutes = [
	{
		path: "/cart",
		name: "cart",
		component: () => import("@/views/pages/cart/Index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/cart/shipment",
		name: "cart_shipment",
		component: () => import("@/views/pages/cart/components/cart_shipment.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/cart/p/waiting-list",
		name: "cart_ongoing",
		component: () => import("@/views/pages/cart/components/cart_waitinglist.vue"),
		meta: { requiresAuth: true },
	},
];

export default cartRoutes;
