const menuRoutes = [
	{
		path: "/menu-profile",
		name: "menu-profile",
		component: () => import("@/views/pages/menu/components/profile-menu.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/edit",
		name: "menu-profile/edit",
		component: () => import("@/views/pages/menu/components/profile-edit.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/kupon",
		name:"menu-profile/kupon",
		component: ()=> import("@/views/pages/menu/components/kupon-menu.vue"),
		meta: {requiresAuth: true},
	},
	{
		path: "/menu-profile/kupon/detail",
		name: "menu-profile/kupon-detail",
		component: ()=> import("@/views/pages/menu/components/kupon-detail.vue"),
		meta: {requiresAuth: true},
	},
	{
		path: "/menu-profile/referal",
		name: "menu-profile/referal",
		component: () => import("@/views/pages/menu/components/referral-menu.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/referal/share",
		name: "referal/share",
		component: () => import("@/views/pages/referal/Index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/referal/redeem",
		name: "referal/redeem",
		component: () =>
			import("@/views/pages/referal/components/referal-redeem.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/edit/list-address",
		name: "menu-profile/edit/address-list",
		component: () =>
			import("@/views/pages/menu/components/list-address.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/edit/ubah-password",
		name: "menu-profile/edit/ubah-password",
		component: () =>
			import("@/views/pages/menu/components/ubah-password.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/edit/keamanan-akun",
		name: "menu-profile/edit/keamanan-akun",
		component: () =>
			import("@/views/pages/keamanan/index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/menu-profile/edit/setting-notifikasi",
		name: "menu-profile/edit/setting-notifikasi",
		component: () =>
			import("@/views/pages/setting-notifikasi/index.vue"),
		meta: { requiresAuth: true },
	},
];
export default menuRoutes;
