import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";

export const useAppStore = defineStore("app", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;

	const visited = ref(false);
	const visitedWelcomePage = ref(useLocalStorage("visitedWelcomePage", false));
	const visitedLanguagePage = ref(useLocalStorage("visitedLanguagePage", false));
	const showSideBar = ref(false);
	const payment_methods = ref([]);

	function updateVisited(value) {
		visited.value = value;
	}

	function updateVisitedWelcomePage(value) {
		visitedWelcomePage.value = value;
	}

	function updateVisitedLanguagePage(value) {
		visitedLanguagePage.value = value;
	}

	async function getPaymentMethod() {
		var res = await axios
			.get(baseUrl + "payment-methods", configs)
			.then((res) => res.data);
		payment_methods.value = res;
	}

	return {
		visited,
		visitedWelcomePage,
		visitedLanguagePage,
		showSideBar,
		payment_methods,

		updateVisited,
		updateVisitedWelcomePage,
		updateVisitedLanguagePage,
		getPaymentMethod,
	};
});
