const mainRoutes = [
	{
		path: "/",
		name: "main",
		component: () => import("@/views/pages/main/Index.vue"),
	},
	{
		path: "/main-advantage1",
		name: "main-advantage1",
		component: () => import("@/views/pages/main/components/Advantage2.vue"),
	},
	{
		path: "/main-advantage2",
		name: "main-advantage2",
		component: () => import("@/views/pages/main/components/Advantage.vue"),
	},
	{
		path: "/choose-language",
		name: "choose-language",
		component: () => import("@/views/pages/main/components/Language.vue"),
	},
	{
		path: "/pusat-bantuan",
		name: "pusat-bantuan",
		component: () => import("@/views/pages/bantuan/index.vue"),
	},
];

export default mainRoutes;
