import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";
import { useSwalStore } from "@/store/swal";
import logo from "@/assets/logo.png";
import { useAddressStore } from "@/store/address";

export const useProductStore = defineStore("product", () => {
	//region states
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const apiUrl = baseUrl + "products-all?";
	const productID = ref(null);
	const productDetail = ref(null);
	const productViewed = ref([]);
	const products = ref([]);
	const productsSearch = ref([]);
	const productsLiked = ref([]);
	const sales = ref([]);
	const recommendations = ref([]);
	const from_stores = ref([]);
	const drinks = ref([]);
	const showDesc = ref(false);
	const showReview = ref(false);
	const token = ref(useLocalStorage("token", ""));
	const cart = ref([]);
	const cart_number = ref(0);
	const cart_number_items = ref(0);
	const cart_summary = ref(null);
	const trx_id = ref(null);
	const showPaymentMethods = ref(false);
	const selectedPaymentMethods = ref({
		name: null,
		code: null,
		category: null,
	});
	const selectedShippingMethods = ref({
		id: 0,
		title: "Metode pengiriman",
		description: "Pilih metode pengiriman",
		method: "no method",
	});
	//end of region states

	//region getters
	const getProducts = computed(() => products);
	const getProductDetail = computed(() => productDetail);
	//end of region getters

	//region actions
	function setSelectedShippingMethods(data) {
		selectedShippingMethods.value = data;
	}

	function getSaleProducts(limit = 15) {
		const url = "type=sale&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	function getRecommendationProducts(limit = 15) {
		const url = "type=recommendation&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	function getFromStoreProducts(limit = 15) {
		const url = "type=store&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	function getDrinkProducts(limit = 15) {
		const url = "type=drink&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	async function getProductsLiked() {
		var res = await axios.get(
			baseUrl + "liked-products?token=" + token.value,
			configs
		);
		productsLiked.value = res.data.data;
		return res;
	}

	async function getProductViewed() {
		var res = await axios.get(
			baseUrl + "viewed-products/?token=" + token.value,
			configs
		);
		productViewed.value = res.data.data;
		return res;
	}

	async function getSearchProducts(value) {
		if (value.length < 1) {
			productsSearch.value = [];
			return;
		}
		var res = await axios
			.get(baseUrl + "product/" + value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Product not Found");
			return;
		}
		productsSearch.value = res.data;
	}

	function updateAll() {
		Promise.all([
			getSaleProducts(),
			getRecommendationProducts(),
			getDrinkProducts(),
			// getProductsLiked(),
			// getFromStoreProducts(),
		]).then(function (results) {
			sales.value = results[0].data.data;
			recommendations.value = results[1].data.data;
			drinks.value = results[2].data.data;
			// productsLiked.value = results[3].data.data;
			// from_stores.value = results[3].data.data;
		});
	}

	async function updateProducts() {
		var res = await axios.get(apiUrl, configs).then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		products.value = res.data;
	}

	async function getProductDetails(id) {
		productID.value = id;
		var res = await axios
			.get(
				baseUrl + "product-details/" + productID.value + "?token=" + token.value,
				configs
			)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		productDetail.value = res.data;
	}

	async function getProductByCategoryID(id) {
		var res = await axios
			.get(baseUrl + "products-by-category/" + id, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		products.value = res.data;
	}

	async function addToCart(id) {
		const sSwal = useSwalStore();
		var res = await axios
			.post(
				baseUrl + "cart-store?token=" + token.value,
				{ product_id: id, quantity: 1, trx_id: trx_id.value },
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(res?.response?.data?.message, 1, true);
			return;
		}

		if (trx_id.value == null) {
			trx_id.value = res.data.trx_id;
		}

		await getProductDetails(id);

		sSwal.swalNotif("Ditambahkan ke keranjang!", 0, true);
		updateCarts();
	}

	async function addToWishlist(status, id) {
		const sSwal = useSwalStore();
		var res = await axios
			.get(baseUrl + "user/favourite/" + id + "?token=" + token.value, configs)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(res?.response?.data?.message, 1, true);
			return;
		}

		await getProductDetails(id);
		// await getProductsLiked();

		if (status == true) {
			sSwal.swalNotif("Ditambahkan ke Disukai!", 0, true);
		} else {
			sSwal.swalNotif("Dikeluarkan dari Disukai!", 0, true);
		}
	}

	function toSnakeCase(str) {
		str = str.toLowerCase();
		str = str.replace(/[\W_]+/g, "_");
		str = str.replace(/([A-Z])/g, "_$1").toLowerCase();
		return str;
	}

	async function updateCarts() {
		var res = await axios
			.get(baseUrl + "carts?token=" + token.value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}

		cart_number.value = 0;
		cart_number_items.value = 0;
		var _carts = _.groupBy(res.data.carts, "shop_name");
		_carts = _.map(_carts, (c) => {
			var _cart = {
				shop_name: c[0].shop_name != "" ? c[0].shop_name : "Murahnih Admin",
				shop_name_class:
					c[0].shop_name != ""
						? toSnakeCase(c[0].shop_name)
						: toSnakeCase("Murahnih Admin"),
				shop_image: c[0].shop_name != "" ? c[0].shop_image : logo,
				shop_selected: false,
				shop_total_items: c.length,
				shop_total: 0,
				items: [],
			};
			_.each(c, (_c) => {
				cart_number.value += _c.quantity;
				cart_number_items.value += 1;
				_cart.shop_total += _c.special_discount_active
					? parseFloat(_c.discount_price) * parseFloat(_c.quantity)
					: parseFloat(_c.price) * parseFloat(_c.quantity);
				_cart.items.push({
					id: _c.id,
					product_image: _c.product_image,
					product_name: _c.product_name,
					category_name: _c.category_name,
					category_id: _c.category_id,
					discount_price: _c.discount_price_formatted,
					price: _c.price_formatted,
					total_price: _c.formatted_sub_total,
					special_discount_active: _c.special_discount_active,
					special_discount_type: _c.special_discount_type,
					special_discount: _c.special_discount_formatted,
					quantity: _c.quantity,
					selected: false,
				});
			});
			return _cart;
		});
		var _data = res.data;
		_data.carts = _carts;
		cart.value = _data;
		trx_id.value = cart.value.trx_id;
	}

	async function updateCartQuantity(id, qty) {
		var res = await axios
			.post(
				baseUrl + "cart-update/" + id + "?token=" + token.value,
				{ quantity: qty },
				configs
			)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}

		updateCarts();
	}

	async function deleteFromCart(id) {
		const sSwal = useSwalStore();
		var res = await sSwal.swalConfirmation(
			"Yakin mau dihapus",
			2,
			"",
			"Ya Hapus!",
			"Batalkan",
			"Berhasil Dihapus!",
			""
		);
		if (res) {
			var res = await axios
				.delete(baseUrl + "cart-delete/" + id + "?token=" + token.value, configs)
				.then((res) => res.data);

			if (!res.success) {
				console.log("Something went wrong!");
				return;
			}

			const sSwal = useSwalStore();
			sSwal.swalNotif("Dihapus dari keranjang!", 0, true);

			updateCarts();
		}
	}

	async function updateCartSummary() {
		console.log(cart.value);
	}

	async function submitOrder() {
		const sSwal = useSwalStore();
		const sAddress = useAddressStore();

		if (!sAddress.selectedAddress?.id) {
			sSwal.swalNotif("Pilih alamat pengiriman terlebih dahulu!", 1, true);
			return false;
		}

		if (selectedShippingMethods.value.id == 0) {
			sSwal.swalNotif("Pilih metode pengiriman terlebih dahulu!", 1, true);
			return false;
		}

		if (!selectedPaymentMethods.value.name) {
			sSwal.swalNotif("Pilih metode pembayaran terlebih dahulu!", 1, true);
			return false;
		}

		var res = await axios
			.post(
				baseUrl + "confirm-order?token=" + token.value,
				{
					trx_id: cart.value.trx_id,
					payment: selectedPaymentMethods.value,
					address_id: sAddress.selectedAddress.id,
					shipping_method: selectedShippingMethods.value.title
						.toUpperCase()
						.replace(" ", "_"),
				},
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(
				res?.response?.data?.data?.message,
				1,
				true,
				"Please contact admin!"
			);
			return;
		}

		// if (selectedPaymentMethods.value.category == "VIRTUAL_ACCOUNT") {
		// 	sSwal.swalNotif(
		// 		"Pesanan terkirim!",
		// 		0,
		// 		true,
		// 		"Anda akan diarahkan ke halaman pembayaran"
		// 	);
		// } else {
		// }
		selectedPaymentMethods.value = {
			name: null,
			code: null,
			category: null,
		};
		sSwal
			.swalNotif(
				"Pesanan terkirim!",
				0,
				true,
				"Anda akan diarahkan ke halaman pembayaran"
			)
			.then(() => {
				window.location.href = "/order/" + res.data.invoice_no + "/payment";
			});
	}
	//end of region actions

	return {
		products,
		productID,
		productDetail,
		productsSearch,
		productsLiked,
		sales,
		recommendations,
		drinks,
		showDesc,
		showReview,
		cart,
		cart_number,
		cart_number_items,
		cart_summary,
		trx_id,
		showPaymentMethods,
		selectedPaymentMethods,
		productViewed,
		selectedShippingMethods,

		from_stores,
		getProducts,
		getProductDetail,

		setSelectedShippingMethods,
		updateProducts,
		updateAll,
		getProductDetails,
		getProductByCategoryID,
		addToCart,
		updateCarts,
		updateCartQuantity,
		deleteFromCart,
		updateCartSummary,
		submitOrder,
		getSearchProducts,
		addToWishlist,
		getProductsLiked,
		getProductViewed,
	};
});
